export default Object.freeze({
	INVOICE: 'invoice',
	PAYMENT_VOID: 'payment-void',
	AUTO_PAYMENT_FAILED: 'auto-payment-failed',
	VOID: 'void',
	WRITE_OFF: 'write-off',
	REFUND: 'refund',
	PAYMENT: 'payment',
	REVERSAL: 'reversal',
	AUCTION_PAYMENT: 'auction-payment',
	PAST_DUE: 'Past Due',
});